.outer-menu {
    position: fixed;
    bottom: 70px;
    right: 70px;
    z-index: 100;
    border-radius: 10px;
}

.outer-menu .checkbox-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0;
    border-radius: 10px;
}

.outer-menu .checkbox-toggle:checked+.hamburger>div {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -webkit-transform: translate3d(0, 0, 0);
}

.outer-menu .checkbox-toggle:checked+.hamburger>div:before,
.outer-menu .checkbox-toggle:checked+.hamburger>div:after {
    top: 0;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.outer-menu .checkbox-toggle:checked+.hamburger>div:after {
    opacity: 0;
}

.outer-menu .checkbox-toggle:checked~.menu {
    pointer-events: auto;
    visibility: visible;
}

.outer-menu .checkbox-toggle:checked~.menu>div {
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    transition-duration: 0.2s;
    -webkit-transform: rotateZ(360deg);
    -webkit-transform: translate3d(0, 0, 0)
}

.outer-menu .checkbox-toggle:checked~.menu>div>div {
    opacity: 1;
    transition: opacity 0.4s ease 0.2s;
}


.outer-menu .checkbox-toggle:checked:hover+.hamburger>div {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -webkit-font-smoothing: antialiased;
    -webkit-transform: rotateZ(360deg);
    -webkit-transform: translate3d(0, 0, 0)
}

.outer-menu .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 65px;
    height: 65px;
    padding: 0.5em 1em;
    border-radius: 50%;

    cursor: pointer;
    /* shadow */
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.outer-menu .hamburger>div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #FEFEFE;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outer-menu .hamburger>div:before,
.outer-menu .hamburger>div:after {
    content: '';
    position: absolute;
    z-index: 100;
    top: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background: inherit;
    transition: all 0.4s ease;
}

.outer-menu .hamburger>div:after {
    top: 10px;
}

/* ------------------------ BAR TAB */


.outer-menu .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outer-menu .menu>div {
    width: 200vw;
    height: 200vw;
    color: #FEFEFE;
    background: rgba(36, 139, 99, 0.897);
    border-radius: 50%;
    transition: all 0.2s ease;
    flex: none;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}