@tailwind base;
@tailwind components;
@tailwind utilities;

.progress-bar {
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(255, 50, 0, 0.8) inset;
  background: repeating-linear-gradient(45deg, #FFFFEE, #FFFFEE 35px, #b43544 40px, #ec4155 75px);
}

.gold {
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(255, 50, 0, 0.8) inset;
  background: repeating-linear-gradient(45deg, #FFFFEE, #FFFFEE 35px, #fcc100 40px, #e0aa35 75px);
}

.font-bungee {
  font-family: 'Bungee', cursive !important;
}

.font-fugaz {
  font-family: 'Fugaz One', sans-serif !important;
}

.title {
  text-shadow: 1px 1px 6px rgb(0 0 0 / 92%), 4px 2px 7px rgb(0 0 0 / 80%);
}

@keyframes pulseCustom {

  0%,
  100% {
    background-color: rgb(70, 160, 118);
  }

  50% {
    background-color: rgb(112, 194, 155);
  }
}

.animate-pulse-custom {
  animation: pulseCustom 2s infinite;
}

select::-webkit-scrollbar {
  width: 20px;
  background-color: red;
}