
.menu-left {
    left: 5px;
    bottom: 70px !important;
  }
.outer-menu .hamburger.bar-tab {
   
    padding: 0.5em 1em;
    background: rgb(224, 79, 79);

    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .outer-menu .bar-tab {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 65px;
    height: 65px;
    padding: 0.5em 1em;
    background: rgba(40, 219, 109, 0.75);
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .outer-menu .bar-tab {
    padding: 0.5em 1em;
    background: rgb(224, 79, 79);

    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .outer-menu .bar-tab > div {
    font-weight: bolder;
    position: relative;
    flex: none;
    font-size: 20px;
    width: 100%;
    height: 2px;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outer-menu .bar-tab > div:before,
  .outer-menu .bar-tab > div:after {
    content: '';
    position: absolute;
    z-index: 100;
    top: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background: inherit;
    transition: all 0.4s ease;
  }
  .outer-menu .bar-tab > div:after {
    top: 10px;
  }


  .outer-menu .checkbox-toggle:checked + .bar-tab > div {
    transform: rotate(135deg);
    background: #FEFEFE;
    color:rgb(224, 79, 79)
  }
  .outer-menu .checkbox-toggle:checked + .bar-tab > div:before,
  .outer-menu .checkbox-toggle:checked + .bar-tab > div:after {
    top: 0;
    transform: rotate(90deg);
  }
  .outer-menu .checkbox-toggle:checked + .bar-tab > div:after {
    opacity: 0;
  }
  .outer-menu .checkbox-toggle:checked ~ .menu {
    pointer-events: auto;
    visibility: visible;
  }
  .outer-menu .checkbox-toggle:checked ~ .menu > div {
    transform: scale(1.00);
    transition-duration: 0.75s;
  }
  .outer-menu .checkbox-toggle:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.4s ease 0.4s;
  }
  .outer-menu .checkbox-toggle:hover + .bar-tab {
  }
  .outer-menu .checkbox-toggle:checked:hover + .bar-tab > div {
    transform: rotate(225deg);
  }
  .outer-menu.menu-left .menu > div {
    background:rgb(224, 79, 79) !important;
  }