#christmas {
    background: #4b6cb4;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-image: url('../../images/cocktailsanta.png');
    background-size: 100%;
    border: 3px solid hsl(0, 0%, 90%);
    position: relative;
    width: 220px;
    height: 220px;
    margin: 10px auto;
    overflow: hidden;
    /* iOS hack for border radius bleed with transforms */
    mask-image: -webkit-radial-gradient(circle, white, black);
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

#christmas .ground {
    -moz-border-radius-topright: 130px 40px;
    -webkit-border-top-right-radius: 130px 40px;
    border-top-right-radius: 130px 40px;
    position: absolute;
    width: 133px;
    height: 55px;
    left: 0;
    bottom: 0;
    background: white;
}

#christmas .ground:before {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: " ";
    position: absolute;
    width: 250px;
    height: 100px;
    top: 10px;
    right: -160px;
    background: white;
}


#christmas .tree {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #23915b transparent;
}

#christmas .tree:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #23915b transparent;
}

#christmas .tree:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #23915b transparent;
}

#christmas .tree.left {
    left: 28px;
    bottom: 78px;
    border-width: 0 18px 24px 18px;
}

#christmas .tree.left:before {
    top: 10px;
    left: -21px;
    border-width: 0 21px 28px 21px;
}

#christmas .tree.left:after {
    top: 22px;
    left: -24px;
    border-width: 0 24px 32px 24px;
}

#christmas .tree.left .snow {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: -2px 2px 0 0 white, 2px 2px 0 0 white, -3px 3px 0 0 white, 3px 3px 0 0 white, -12px 14px 0 0 white, -13px 17px 0 1px white, -16px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 11px 14px 0 1px white, 13px 17px 0 1px white, 15px 19px 0 0 white;
    -webkit-box-shadow: -2px 2px 0 0 white, 2px 2px 0 0 white, -3px 3px 0 0 white, 3px 3px 0 0 white, -12px 14px 0 0 white, -13px 17px 0 1px white, -16px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 11px 14px 0 1px white, 13px 17px 0 1px white, 15px 19px 0 0 white;
    box-shadow: -2px 2px 0 0 white, 2px 2px 0 0 white, -3px 3px 0 0 white, 3px 3px 0 0 white, -12px 14px 0 0 white, -13px 17px 0 1px white, -16px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 11px 14px 0 1px white, 13px 17px 0 1px white, 15px 19px 0 0 white;
    position: absolute;
    top: 1px;
    left: -3px;
    width: 6px;
    height: 6px;
    background: white;
    z-index: 1;
}

#christmas .tree.left .snow:after {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: -14px 14px 0 0 white, -15px 17px 0 1px white, -18px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 12px 17px 0 3px white, 16px 17px 0 1px white, 18px 19px 0 0 white, 11px 20px 0 1px white, 16px 20px 0 1px white, 18px 22px 0 1px white, 21px 25px 0 0 white, -2px 27px 0 1px white;
    -webkit-box-shadow: -14px 14px 0 0 white, -15px 17px 0 1px white, -18px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 12px 17px 0 3px white, 16px 17px 0 1px white, 18px 19px 0 0 white, 11px 20px 0 1px white, 16px 20px 0 1px white, 18px 22px 0 1px white, 21px 25px 0 0 white, -2px 27px 0 1px white;
    box-shadow: -14px 14px 0 0 white, -15px 17px 0 1px white, -18px 19px 0 0 white, -9px 16px 0 0 white, -5px 15px 0 2px white, -9px 13px 0 0 white, 6px 15px 0 2px white, 0 13px 0 1px white, 12px 17px 0 3px white, 16px 17px 0 1px white, 18px 19px 0 0 white, 11px 20px 0 1px white, 16px 20px 0 1px white, 18px 22px 0 1px white, 21px 25px 0 0 white, -2px 27px 0 1px white;
    content: " ";
    position: absolute;
    top: 14px;
    left: 0px;
    width: 6px;
    height: 6px;
    background: white;
    z-index: 1;
}

#christmas .tree.right {
    left: 60px;
    bottom: 45px;
    border-width: 0 18px 24px 18px;
}

#christmas .tree.right:before,
#christmas .tree.right:after {
    display: none;
}

#christmas .tree.right .snow {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-box-shadow: -1px 2px 0 0 white, -2px 3px 0 0 white, 2px 2px 0 0 white, 3px 3px 0 0 white, 4px 4px 0 0 white, 6px 6px 0 0 white, 6px 15px 0 0 white, 8px 16px 0 0 white, 10px 16px 0 0 white, 11px 16px 0 0 white, -5px 13px 0 0 white, -7px 12px 0 0 white;
    -webkit-box-shadow: -1px 2px 0 0 white, -2px 3px 0 0 white, 2px 2px 0 0 white, 3px 3px 0 0 white, 4px 4px 0 0 white, 6px 6px 0 0 white, 6px 15px 0 0 white, 8px 16px 0 0 white, 10px 16px 0 0 white, 11px 16px 0 0 white, -5px 13px 0 0 white, -7px 12px 0 0 white;
    box-shadow: -1px 2px 0 0 white, -2px 3px 0 0 white, 2px 2px 0 0 white, 3px 3px 0 0 white, 4px 4px 0 0 white, 6px 6px 0 0 white, 6px 15px 0 0 white, 8px 16px 0 0 white, 10px 16px 0 0 white, 11px 16px 0 0 white, -5px 13px 0 0 white, -7px 12px 0 0 white;
    position: absolute;
    top: 0;
    left: -3px;
    width: 6px;
    height: 6px;
    background: white;
}

#christmas .flake {
    -webkit-animation: snow infinite linear 30s;
    -moz-animation: snow infinite linear 30s;
    -ms-animation: snow infinite linear 30s;
    -o-animation: snow infinite linear 30s;
    animation: snow infinite linear 30s;
    position: absolute;
    width: 1px;
    height: 1px;
    background: white;
}

#christmas .flake.large {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 4px;
    height: 4px;
}

#christmas .flake.f-1 {
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
    -ms-animation-duration: 20s;
    -o-animation-duration: 20s;
    animation-duration: 20s;
    left: 15px;
    margin-top: 86px;
}

#christmas .flake.f-2 {
    -webkit-animation-duration: 23s;
    -moz-animation-duration: 23s;
    -ms-animation-duration: 23s;
    -o-animation-duration: 23s;
    animation-duration: 23s;
    left: 32px;
    margin-top: 52px;
}

#christmas .flake.f-3 {
    -webkit-animation-duration: 32s;
    -moz-animation-duration: 32s;
    -ms-animation-duration: 32s;
    -o-animation-duration: 32s;
    animation-duration: 32s;
    left: 64px;
    margin-top: 15px;
}

#christmas .flake.f-4 {
    -webkit-animation-duration: 21s;
    -moz-animation-duration: 21s;
    -ms-animation-duration: 21s;
    -o-animation-duration: 21s;
    animation-duration: 21s;
    left: 69px;
    margin-top: 34px;
}

#christmas .flake.f-5 {
    -webkit-animation-duration: 24s;
    -moz-animation-duration: 24s;
    -ms-animation-duration: 24s;
    -o-animation-duration: 24s;
    animation-duration: 24s;
    left: 70px;
    margin-top: 72px;
}

#christmas .flake.f-6 {
    -webkit-animation-duration: 16s;
    -moz-animation-duration: 16s;
    -ms-animation-duration: 16s;
    -o-animation-duration: 16s;
    animation-duration: 16s;
    left: 90px;
    margin-top: 97px;
}

#christmas .flake.f-7 {
    -webkit-animation-duration: 34s;
    -moz-animation-duration: 34s;
    -ms-animation-duration: 34s;
    -o-animation-duration: 34s;
    animation-duration: 34s;
    left: 102px;
    margin-top: 81px;
}

#christmas .flake.f-8 {
    -webkit-animation-duration: 37s;
    -moz-animation-duration: 37s;
    -ms-animation-duration: 37s;
    -o-animation-duration: 37s;
    animation-duration: 37s;
    left: 102px;
    margin-top: 40px;
}

#christmas .flake.f-9 {
    -webkit-animation-duration: 35s;
    -moz-animation-duration: 35s;
    -ms-animation-duration: 35s;
    -o-animation-duration: 35s;
    animation-duration: 35s;
    left: 108px;
    margin-top: 67px;
}

#christmas .flake.f-10 {
    -webkit-animation-duration: 23s;
    -moz-animation-duration: 23s;
    -ms-animation-duration: 23s;
    -o-animation-duration: 23s;
    animation-duration: 23s;
    left: 90px;
    margin-top: 11px;
}

#christmas .flake.f-11 {
    -webkit-animation-duration: 27s;
    -moz-animation-duration: 27s;
    -ms-animation-duration: 27s;
    -o-animation-duration: 27s;
    animation-duration: 27s;
    left: 126px;
    margin-top: 55px;
}

#christmas .flake.f-12 {
    -webkit-animation-duration: 29s;
    -moz-animation-duration: 29s;
    -ms-animation-duration: 29s;
    -o-animation-duration: 29s;
    animation-duration: 29s;
    left: 131px;
    margin-top: 27px;
}

#christmas .flake.f-13 {
    -webkit-animation-duration: 24s;
    -moz-animation-duration: 24s;
    -ms-animation-duration: 24s;
    -o-animation-duration: 24s;
    animation-duration: 24s;
    left: 128px;
    margin-top: 112px;
}

#christmas .flake.f-14 {
    -webkit-animation-duration: 23s;
    -moz-animation-duration: 23s;
    -ms-animation-duration: 23s;
    -o-animation-duration: 23s;
    animation-duration: 23s;
    left: 146px;
    margin-top: 72px;
}

#christmas .flake.f-15 {
    -webkit-animation-duration: 19s;
    -moz-animation-duration: 19s;
    -ms-animation-duration: 19s;
    -o-animation-duration: 19s;
    animation-duration: 19s;
    left: 147px;
    margin-top: 102px;
}

#christmas .flake.f-16 {
    -webkit-animation-duration: 22s;
    -moz-animation-duration: 22s;
    -ms-animation-duration: 22s;
    -o-animation-duration: 22s;
    animation-duration: 22s;
    left: 166px;
    margin-top: 100px;
}

#christmas .flake.f-17 {
    -webkit-animation-duration: 18s;
    -moz-animation-duration: 18s;
    -ms-animation-duration: 18s;
    -o-animation-duration: 18s;
    animation-duration: 18s;
    left: 177px;
    margin-top: 73px;
}

#christmas .flake.f-18 {
    -webkit-animation-duration: 26s;
    -moz-animation-duration: 26s;
    -ms-animation-duration: 26s;
    -o-animation-duration: 26s;
    animation-duration: 26s;
    left: 22px;
    margin-top: 79px;
}

#christmas .flake.f-19 {
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
    -ms-animation-duration: 20s;
    -o-animation-duration: 20s;
    animation-duration: 20s;
    left: 31px;
    margin-top: 41px;
}

#christmas .flake.f-20 {
    -webkit-animation-duration: 19s;
    -moz-animation-duration: 19s;
    -ms-animation-duration: 19s;
    -o-animation-duration: 19s;
    animation-duration: 19s;
    left: 38px;
    margin-top: 56px;
}

#christmas .flake.f-21 {
    -webkit-animation-duration: 31s;
    -moz-animation-duration: 31s;
    -ms-animation-duration: 31s;
    -o-animation-duration: 31s;
    animation-duration: 31s;
    left: 56px;
    margin-top: 43px;
}

#christmas .flake.f-22 {
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
    -ms-animation-duration: 20s;
    -o-animation-duration: 20s;
    animation-duration: 20s;
    left: 89px;
    margin-top: 57px;
}

#christmas .flake.f-23 {
    -webkit-animation-duration: 18s;
    -moz-animation-duration: 18s;
    -ms-animation-duration: 18s;
    -o-animation-duration: 18s;
    animation-duration: 18s;
    left: 90px;
    margin-top: 30px;
}

#christmas .flake.f-24 {
    -webkit-animation-duration: 28s;
    -moz-animation-duration: 28s;
    -ms-animation-duration: 28s;
    -o-animation-duration: 28s;
    animation-duration: 28s;
    left: 100px;
    margin-top: 119px;
}

#christmas .flake.f-25 {
    -webkit-animation-duration: 29s;
    -moz-animation-duration: 29s;
    -ms-animation-duration: 29s;
    -o-animation-duration: 29s;
    animation-duration: 29s;
    left: 114px;
    margin-top: 14px;
}

#christmas .flake.f-26 {
    -webkit-animation-duration: 25s;
    -moz-animation-duration: 25s;
    -ms-animation-duration: 25s;
    -o-animation-duration: 25s;
    animation-duration: 25s;
    left: 127px;
    margin-top: 84px;
}

#christmas .flake.f-27 {
    -webkit-animation-duration: 26s;
    -moz-animation-duration: 26s;
    -ms-animation-duration: 26s;
    -o-animation-duration: 26s;
    animation-duration: 26s;
    left: 134px;
    margin-top: 114px;
}

#christmas .flake.f-28 {
    -webkit-animation-duration: 34s;
    -moz-animation-duration: 34s;
    -ms-animation-duration: 34s;
    -o-animation-duration: 34s;
    animation-duration: 34s;
    left: 185px;
    margin-top: 49px;
}

#christmas .flake.f-29 {
    -webkit-animation-duration: 29s;
    -moz-animation-duration: 29s;
    -ms-animation-duration: 29s;
    -o-animation-duration: 29s;
    animation-duration: 29s;
    left: 200px;
    margin-top: 42px;
}

#christmas .flake.f-30 {
    -webkit-animation-duration: 23s;
    -moz-animation-duration: 23s;
    -ms-animation-duration: 23s;
    -o-animation-duration: 23s;
    animation-duration: 23s;
    left: 210;
    margin-top: 70px;
}

#christmas .flake.f-31 {
    -webkit-animation-duration: 18s;
    -moz-animation-duration: 18s;
    -ms-animation-duration: 18s;
    -o-animation-duration: 18s;
    animation-duration: 18s;
    left: 220px;
    margin-top: 78px;
}

@-webkit-keyframes snow {
    0% {
        -moz-transform: translateY(-110px);
        -ms-transform: translateY(-110px);
        -webkit-transform: translateY(-110px);
        transform: translateY(-110px);
    }

    100% {
        -moz-transform: translateY(160px);
        -ms-transform: translateY(160px);
        -webkit-transform: translateY(160px);
        transform: translateY(160px);
    }
}

@-moz-keyframes snow {
    0% {
        -moz-transform: translateY(-110px);
        -ms-transform: translateY(-110px);
        -webkit-transform: translateY(-110px);
        transform: translateY(-110px);
    }

    100% {
        -moz-transform: translateY(160px);
        -ms-transform: translateY(160px);
        -webkit-transform: translateY(160px);
        transform: translateY(160px);
    }
}

@-ms-keyframes snow {
    0% {
        -moz-transform: translateY(-110px);
        -ms-transform: translateY(-110px);
        -webkit-transform: translateY(-110px);
        transform: translateY(-110px);
    }

    100% {
        -moz-transform: translateY(160px);
        -ms-transform: translateY(160px);
        -webkit-transform: translateY(160px);
        transform: translateY(160px);
    }
}

@keyframes snow {
    0% {
        -moz-transform: translateY(-110px);
        -ms-transform: translateY(-110px);
        -webkit-transform: translateY(-110px);
        transform: translateY(-110px);
    }

    100% {
        -moz-transform: translateY(160px);
        -ms-transform: translateY(160px);
        -webkit-transform: translateY(160px);
        transform: translateY(160px);
    }
}